const state = {
  inviteModal: false,
};

const mutations = {
  SET_INVITE_MODAL(state, value) {
    state.inviteModal = value;
  },
};

const actions = {
  setInviteModal({ commit }, value) {
    commit('SET_INVITE_MODAL', value);
  },
};

const getters = {
  getModals: (state) => state,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};