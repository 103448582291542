import { createStore } from 'vuex';
import user from './modules/user';
import stories from './modules/stories';
import products from './modules/products';
import categories from './modules/categories';
import cart from './modules/cart';
import modals from './modules/modals';

export default createStore({
  modules: {
    user,
    stories,
    products,
    categories,
    cart,
    modals,
  },
});