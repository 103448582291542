<template>
  <transition
    leave-active-class="animate__animated animate__slideOutDown"
  >
    <div v-if="isVisible" class="invite-modal-overlay animate__animated animate__slideInUp">
      <div class="invite-modal" ref="modal" @mousedown="startDrag" @touchstart="startDrag">
        <div class="utils">
          <div class="close" @click="closeModal"></div>
        </div>
        <div class="header">
          <div class="icon"></div>
          <a>Пригласить друга</a>
        </div>
        <div class="how-it-works">
          <div class="title">
            <img src="../../assets/icons/info.svg" alt="">
            Как это работает?
          </div>
          <div class="description">
            <a>1. Просто поделитесь ссылкой с другом</a>
            <a>2. Перешедший получит бонусный баланс при пополнении</a>
            <a>3. Вы будете получать бонус при каждом пополнении от рефералов</a>
          </div>
        </div>
        <div class="invite-content">
          <a>Ссылка для друга</a>
          <a id="referal-link">REF-1CZAZS4</a>
          <div class="copy-it">
            <img src="../../assets/icons/copy.svg" alt="">
            Скопировать
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isVisible: true,
      isDragging: false,
      startY: 0,
      startHeight: 0,
      minHeight: 100, // Минимальная высота
      maxHeight: window.innerHeight, // Максимальная высота (высота экрана)
    }
  },
  methods: {
    ...mapActions('modals', ['setInviteModal']),

    closeModal() {
      this.isVisible = false;
      setTimeout(() => {
        this.setInviteModal(false);
        this.$emit('close');
      }, 500);
    },

    startDrag(event) {
      if (this.$refs.modal) {
        this.isDragging = true;
        this.startY = event.touches ? event.touches[0].clientY : event.clientY;
        this.startHeight = this.$refs.modal.offsetHeight;
        document.addEventListener('mousemove', this.doDrag);
        document.addEventListener('mouseup', this.stopDrag);
        document.addEventListener('touchmove', this.doDrag);
        document.addEventListener('touchend', this.stopDrag);
      }
    },

    doDrag(event) {
      if (this.isDragging && this.$refs.modal) {
        const currentY = event.touches ? event.touches[0].clientY : event.clientY;
        const deltaY = this.startY - currentY;
        let newHeight = this.startHeight + deltaY;

        // Ограничиваем высоту в пределах от minHeight до maxHeight
        newHeight = Math.max(this.minHeight, Math.min(newHeight, this.maxHeight));

        this.$refs.modal.style.height = `${newHeight}px`;
        
        if (newHeight <= this.minHeight) {
          this.closeModal();
        }
      }
    },

    stopDrag() {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.doDrag);
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('touchmove', this.doDrag);
      document.removeEventListener('touchend', this.stopDrag);
    },
  }
}
</script>

<style scoped>
.invite-modal-overlay {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
  background: linear-gradient(180deg, #1E1E1E 0%, #1A1A1A 100%);
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.08);
  z-index: 2;

  left: 0;
  overflow-y: hidden;
}
.invite-modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  height: 80vh;
  overflow-y: hidden;
}
.invite-modal .utils {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.invite-modal .utils .close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: url(../../assets/icons/close.svg) no-repeat center, rgba(255, 255, 255, 0.12);
  background-size: 1.25rem;
  cursor: pointer;
  opacity: 0.8;
  z-index: 3;
}
.invite-modal .header {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.invite-modal .header .icon {
  position: relative;
  width: 5.5rem;
  height: 5.5rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.0144) 100%), url(../../assets/icons/invite-modal.svg) no-repeat center;
  background-size: 2.5rem;
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 1.5rem;
}
.invite-modal .header .icon::before {
  content: '';
  position: absolute;
  top: -12rem;
  left: -150%;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(40, 118, 181, 0.6) 0%, rgba(40, 118, 181, 0) 70%);
  z-index: -1;
  width: 400%;
  height: 400%;
  border-radius: 50%;
}
.invite-modal .header a {
  margin-top: 1rem;
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;
}
.invite-modal .how-it-works {
  margin-top: 1.25rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 1.5rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.0144) 100%);
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 1.5rem;
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 1.5rem;
}
.invite-modal .how-it-works .title {
  display: flex;
  align-items: center;
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1rem;
}
.invite-modal .how-it-works .title img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
}
.invite-modal .how-it-works .description {
  display: flex;
  flex-direction: column;
}
.invite-modal .how-it-works .description a {
  font-family: "SF-Pro-Display-medium";
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  margin-top: 0.8rem;
}
.invite-modal .invite-content {
  position: relative;
  margin-top: 1.25rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1.5rem;
  background: #2876B5;
  background-image: url(../../assets/icons/coupon.svg);
  background-repeat: no-repeat;
  background-position: right center;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 1.5rem;
}
.invite-modal .invite-content a {
  font-family: "SF-Pro-Display-semibold";
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
}
#referal-link {
  font-size: 2rem;
}
.invite-modal .invite-content .copy-it {
  margin-top: 0.5rem;
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SF-Pro-Display-semibold";
  color: #000;
  font-size: 1rem;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 1rem;
}
</style>
